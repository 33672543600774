<template>
  <div
    :class="isButton ? 'svg-container btn-icon' : 'svg-container'"
    @click="action"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16.147"
      viewBox="0 0 16 16.147"
    >
      <g transform="translate(-200.137 -435)">
        <path
          d="M6.64,9.558A2.812,2.812,0,0,0,9.456,6.741,2.9,2.9,0,0,0,6.64,3.823,2.812,2.812,0,0,0,3.823,6.64,2.969,2.969,0,0,0,6.64,9.558ZM1.911,1.912a6.687,6.687,0,0,1,9.457,9.457L6.64,16.1,1.911,11.368A6.869,6.869,0,0,1,1.911,1.912Z"
          transform="translate(201.09 435.05)"
          :fill="iconColor"
          fill-rule="evenodd"
        />
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    iconColor: {
      type: String,
      default: "#FFFFFF",
    },
    isButton: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    action() {
      this.$emit("action");
    },
  },
};
</script>
