<template>
  <div :class="classButton + 'svg-close-container'" @click="action()">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19.394"
      height="19.897"
      viewBox="0 0 19.394 19.897"
    >
      <path
        d="M9.429,13.341,1.76,21.01,0,19.249,7.669,11.58.033,3.945l1.76-1.76L9.429,9.821,17.6,1.649l1.76,1.76-8.172,8.172,8.205,8.2-1.76,1.76Z"
        transform="translate(0 -1.649)"
      />
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    isButton: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      classButton: this.isButton ? "btn-icon " : "",
    };
  },
  methods: {
    action() {
      this.$emit("action");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "/Icons";
</style>
